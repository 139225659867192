const heroSlider = () => {

    const slider = $('.js-hero-slider');

    if ( slider.length ) {
        const hiddenClass  = 'hero-slider--hidden';

        slider.on('init', function(event, slick){
            slider.removeClass(hiddenClass);
        });

        slider.on('init breakpoint', function(event, slick){
            const controls = slider.find('.js-hero-slider-controls');
            controls.appendTo(slider);
        });

        slider.slick({
            slide           : '.hero-slider__element',
            rows            : 0,
            variableWidth   : true,
            slidesToShow    : 3,
            infinite        : true,
            arrows          : true,
            dots            : true,
            prevArrow       : '<button type="button" class="slick-prev"><svg class="i"><use xlink:href="#arrow-left"/></svg></button>',
            nextArrow       : '<button type="button" class="slick-next"><svg class="i"><use xlink:href="#arrow-right"/></svg></button>',
            centerMode      : true,
            centerPadding   : '0',
            appendArrows    : $('.js-hero-slider-arrows', slider),
            appendDots      : $('.js-hero-slider-dots', slider),
            responsive      : [
                {
                    breakpoint: 991,
                    settings: {
                        arrows: false
                    }
                }
            ],
            customPaging : function(slider, i) {
                const title = $(slider.$slides[i]).attr('data-hero-title');
                return '<button class="pager__item" type="button"> '+ title +' </button>';
            }
        });
    }
}